<template>
    <div class="row">
        <div class="back"></div>
        <div class="col-lg-12 first">
            <div class="header_top" style="position: relative">
                <h1 class="animation01" style="opacity: 0;position: absolute">
                    {{$t('ContainerMessage.ContainerTitle2')}}</h1>
                <h1 class="animation02" style="opacity: 0;position: absolute">
                    {{$t('ContainerMessage.ContainerTitle')}}</h1>

            </div>
            <div class="header_container">
                <h4 class="wenzishow">{{$t('ContainerMessage.ContainerDescript1')}}</h4>
                <h4 class="wenzishow">{{$t('ContainerMessage.ContainerDescript2')}}
                </h4>
                <h4 class="wenzishow">{{$t('ContainerMessage.ContainerDescript3')}}</h4>
                <h4 class="wenzishow">{{$t('ContainerMessage.ContainerDescript4')}}</h4>
                <h4 class="wenzishow">{{$t('ContainerMessage.ContainerDescript5')}}</h4>
            </div>
            <div class="header_bottom">
                <a @click="gotoabout" class="animation02" href="javascript:;" style="opacity: 0;position: absolute">{{$t('ContainerMessage.ContainerMoreAbout')}}</a>
            </div>
        </div>

        <div class="col-lg-12" style="height: 1850px;">
            <h1 class="title">{{$t('ContainerMessage.ContainerReasion')}}</h1>
            <div class="dircplity">
                <p>{{$t('ContainerMessage.ContainerReasion1')}}</p>
                <p>{{$t('ContainerMessage.ContainerReasion2')}}</p>
            </div>

            <div class="container_ul">


                <div class="container_ul_first">

                    <img src="../../../assets/image/index/01.webp">
                </div>


                <div class="container_ul_first">
                    <h4 class="wenzishow02">{{$t('ContainerMessage.ContainerBelieve')}}</h4>
                    <div class="container_ul_first_fenge wenzishow02"></div>
                    <div class="container_ul_first_world">
                        <p class="wenzishow02">{{$t('ContainerMessage.ContainerLeader')}}</p>
                    </div>
                </div>
                <div class="container_ul_first">
                    <img src="../../../assets/image/index/02.webp">
                </div>
                <div class="container_ul_first">
                    <h4 class="wenzishow02">{{$t('ContainerMessage.ContainerReliable')}}</h4>
                    <div class="container_ul_first_fenge wenzishow02"></div>
                    <div class="container_ul_first_world ">
                        <p class="wenzishow02">{{$t('ContainerMessage.ContainerReliable1')}}</p>
                    </div>
                </div>


                <div class="container_ul_first">
                    <h4 class="wenzishow03">{{$t('ContainerMessage.ContainerFlow')}}</h4>
                    <div class="container_ul_first_fenge wenzishow03"></div>
                    <div class="container_ul_first_world">
                        <p :style="this.localLanguageName !=='en'? 'margin-top: 25px;' :'' " class="wenzishow03">

                            {{$t('ContainerMessage.ContainerFlow1')}}</p>
                    </div>
                </div>


                <div class="container_ul_first">
                    <img src="../../../assets/image/index/03.webp">
                </div>


                <div class="container_ul_first">
                    <h4 class="wenzishow03"> {{$t('ContainerMessage.ContainerEfficient')}}</h4>
                    <div class="container_ul_first_fenge wenzishow03"></div>
                    <div class="container_ul_first_world">
                        <p class="wenzishow03">{{$t('ContainerMessage.ContainerEfficient1')}}</p>
                    </div>
                </div>
                <div class="container_ul_first">
                    <img src="../../../assets/image/index/04.webp">
                </div>


                <div class="container_ul_first">
                    <img src="../../../assets/image/index/05.webp">
                </div>


                <div class="container_ul_first">
                    <h4 class="wenzishow04">{{$t('ContainerMessage.ContainerSpeedy')}}</h4>
                    <div class="container_ul_first_fenge wenzishow04"></div>
                    <div class="container_ul_first_world">
                        <p class="wenzishow04">

                            {{$t('ContainerMessage.ContainerSpeedy1')}}</p>
                    </div>
                </div>
                <div class="container_ul_first">
                    <img src="../../../assets/image/index/06.webp">
                </div>
                <div class="container_ul_first">
                    <h4 class="wenzishow04">{{$t('ContainerMessage.ContainerProfessionAccept')}}</h4>
                    <div class="container_ul_first_fenge wenzishow04"></div>
                    <div class="container_ul_first_world">
                        <p :style="this.localLanguageName !=='en'? 'margin-top: 15px;' :'' " class="wenzishow04">
                            {{$t('ContainerMessage.ContainerProfessionAccept1')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12" style="margin-top: -350px">
            <h1 class="professional">{{$t('ContainerMessage.ContainerServeGood')}}</h1>
        </div>

        <div class="col-lg-12" style="height: 1000px;">

            <div style="height: 1000px;width: 98%;position: relative;left: 50%;transform: translate(-50%)">

            <div class="conatinner_last">

                <img alt="" src="../../../assets/image/index/07.webp">

                <div class="container_last_world">
                    <h1> {{$t('ContainerMessage.ContainerItManage')}}</h1>
                    <p class="container_last_world_title">{{$t('ContainerMessage.ContainerIt1')}}</p>
                    <div class="container_last_world_last">
                        <p>
                            {{$t('ContainerMessage.ContainerIt2')}}</p>
                    </div>
                    <button  @click="gotoMoreInfo" class="buttonanimation" type="button">
                        {{$t('ContainerMessage.ContainerIt3') }}
                    </button>
                </div>
            </div>

            <div class="conatinner_last">

                <img alt="" src="../../../assets/image/index/08.webp">

                <div class="container_last_world">
                    <h1> {{$t('ContainerMessage.ContainerDataSafter')}}</h1>
                    <p class="container_last_world_title">{{$t('ContainerMessage.ContainerDataSafter1')}}</p>
                    <div class="container_last_world_last">
                        <p>
                            {{$t('ContainerMessage.ContainerDataSafter2')}}</p>
                    </div>
                    <button @click="gotoMoreInfo" class="buttonanimation" type="button">
                        {{$t('ContainerMessage.ContainerDataSafter3')}}
                    </button>
                </div>
            </div>

            <div class="conatinner_last">

                <img alt="" src="../../../assets/image/index/09.webp">

                <div class="container_last_world">
                    <h1> {{$t('ContainerMessage.ContainerFlowManage')}}</h1>
                    <p class="container_last_world_title">{{$t('ContainerMessage.ContainerFlowManage1')}}</p>
                    <div class="container_last_world_last">
                        <div style="height: 75px;width: 350px;margin-left: 55px;text-align: left;">
                            <h6 style="color: black;line-height: 25px;">
                                {{$t('ContainerMessage.ContainerFlowManage2')}}</h6>
                        </div>
                        <p style="display: inline-block;margin-left: -105px">
                            {{$t('ContainerMessage.ContainerFlowManage3')}}​</p>
                        <div class="ququ">
                            <span>{{$t('ContainerMessage.ContainerFlowManage4')}}</span>
                            <span>{{$t('ContainerMessage.ContainerFlowManage5')}}</span>
                            <span>{{$t('ContainerMessage.ContainerFlowManage6')}}</span>
                            <span>{{$t('ContainerMessage.ContainerFlowManage7')}}</span>
                            <span>{{$t('ContainerMessage.ContainerFlowManage8')}}</span>
                        </div>
                    </div>
                    <button @click="gotoMoreInfo" class="buttonanimation" type="button">
                        {{$t('ContainerMessage.ContainerFlowManage9')}}
                    </button>
                </div>
            </div>
</div>
        </div>

    </div>

    <!--        <el-row>-->

    <!--            <el-button :disabled="$i18n.locale == 'zh'" @click.native="toggleLang('zh')" type="primary">主要按钮</el-button>-->
    <!--            <el-button :disabled="$i18n.locale == 'en'" @click.native="toggleLang('en')" type="success">成功按钮</el-button>-->
    <!--            <el-button @click="about" type="success">about</el-button>-->
    <!--        </el-row>-->


</template>

<script>
    export default {
        name: "Container",
        data() {
            return {
                localLanguageName: '',

            }
        },

        mounted() {
            this.localLanguageName =  this.$i18n.locale = localStorage.getItem('locale')
        },
        computed:{

        },



        methods: {
            gotoabout() {
                this.$router.replace('/about')
            },
            gotoMoreInfo() {
                this.$router.replace('/itciorecycle')
            }
        },
        created() {




            $(function () {
                $('.animation02').animate({
                    opacity:  '1',
                    top: '15px'
                }, 1000)

                $('.animation01').animate({
                    opacity: '1',
                    top: '60px'
                }, 1000)

                $('.wenzishow').animate({
                    opacity: '1',
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();

                    if (s > 170) {
                        $('.wenzishow02').animate({
                            opacity: '1',

                        }, 2000)
                    }
                    if (s > 420) {
                        $('.wenzishow03').animate({
                            opacity: '1',

                        }, 2000)
                    }
                    if (s > 800) {
                        $('.wenzishow04').animate({
                            opacity: '1',
                        }, 2000)
                    }
                    if (s > 2314) {
                        $('.buttonanimation').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }


                });


            })
        }

    }


    $(function () {
        $('.animation02').animate({
            opacity: '1',
            top: '15px'
        }, 1000)

        $('.animation01').animate({
            opacity: '1',
            top: '60px'
        }, 1000)

        $('.wenzishow').animate({
            opacity: '1',
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 170) {
                $('.wenzishow02').animate({
                    opacity: '1',

                }, 2000)
            }
            if (s > 420) {
                $('.wenzishow03').animate({
                    opacity: '1',

                }, 2000)
            }
            if (s > 800) {
                $('.wenzishow04').animate({
                    opacity: '1',
                }, 2000)
            }
            if (s > 2314) {
                $('.buttonanimation').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }


        });


    })


</script>

<style scoped>
    .wenzishow {
        opacity: 0;
    }

    .wenzishow02 {
        opacity: 0;

    }

    .wenzishow03 {
        opacity: 0;
    }

    .buttonanimation {
        opacity: 0;
        position: relative;

    }

    .wenzishow04 {
        opacity: 0;


    }

    .ququ {
        height: 145px;
        width: 320px;
        margin-top: -25px;
        margin-left: 86px;

    }

    .ququ span {
        line-height: 20px !important;
        text-align: left;
        display: inline-block;
        width: 100%;
        color: black;
        font-size: 14px !important;
    }

    .ququ span::before {
        content: "";
        height: 7px;
        width: 7px;
        position: absolute;
        background-color: black;
        border-radius: 50%;
        margin-left: -10px;
        margin-top: 7px;
    }

    .row {
        position: relative;
    }

    .row .back {
       width: 100%;
        height: 550px;
        position: absolute;
        background-color: black;
        opacity: 0.2;
        z-index: 1;
        margin-top: -190px;


    }

    .row .first {
        height: 550px;
        background: url("../../../assets/images/background.jpg") no-repeat;
        background-size: 100% 100%;
        margin-top: -190px;

    }

    .col-lg-12 .header_top {
        height: 150px;
        width: 50%;
        position: relative;
        left: 25%;
        margin-top: 115px;
        z-index: 99;

    }

    .header_top h1 {
        color: #ffffff;
        line-height: 45px;
        margin-top: 10px;
        z-index: 99;
        height: 45px;

    }

    .col-lg-12 .header_container {
        height: 200px;
        position: relative;
        left: 25%;
        z-index: 99;
        width: 800px;
        margin-top: 5px;

    }

    .header_container h4 {
        color: white;
        line-height: 25px;
        font-size: 15px !important;


    }

    .col-lg-12 .header_bottom {
        height: 40px;
        width: 250px;
        position: relative;
        left: 25%;
        z-index: 99;

    }

    .header_bottom a {
        color: #ffffff;
        text-decoration: none;

        display: inline-block;
        height: 45px;
        line-height: 45px;
        width: 105px;
        text-align: center;
        background-color: silver;
        border-radius: 5px;
    }

    .header_bottom a:hover {

        text-decoration: none;
        background-color: sandybrown;
        border-radius: 5px;
    }

    .col-lg-12 .title {
        text-align: center;
        margin-top: 50px;
        color: black;
    }

    .col-lg-12 .dircplity {
        height: 180px;

        width: 880px;
        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 100px;
    }

    .dircplity p {
        color: black;
        line-height: 22px;

    }

    .dircplity p:last-child {
        margin-top: -10px;
    }

    .col-lg-12 .container_ul {
        height: 1143px;
        width: 1885px;
        position: relative;
        left: 62%;
        transform: translate(-50%);
        overflow-x: hidden;

    }

    .container_ul .container_ul_first {
        float: left;
        height: 380px;
        width: 380px;
        background-color: #f1f1f1;
        text-align: center;

    }

    .container_ul_first img {
        height: 380px;
        width: 380px;
    }

    .container_ul_first h4 {
        color: black;
        font-weight: 800;
        margin-top: 120px;
        text-align: center;
    }

    .container_ul_first .container_ul_first_fenge {
        height: 2px;
        width: 150px;
        background-color: black;
        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);


    }

    .container_ul_first .container_ul_first_world {
        width: 200px;
        position: relative;
        transform: translate(-50%, -50%);
        left: 50%;
       height: 200px;
        margin-top: 100px;
        color: black;


    }

    .container_ul_first .container_ul_first_world p {
        color: black;
        font-weight: bold;

    }

    .col-lg-12 .professional {
        text-align: center;
        line-height: 145px;
        color: black;
        font-weight: 800;

    }

    .col-lg-12 .conatinner_last {
        height: 900px;
        float: left;
        width: 33.3%;
        position: relative;
        left: 16.8%;
        transform: translate(-50%);

    }


    .conatinner_last img {
        text-align: center;
        height: 400px;
        width: 483px;
        position: relative;
        left: 50%;
        transform: translate(-50%);

    }

    .conatinner_last .container_last_world {
        height: 580px;
        width: 483px;
        text-align: center;
        background-color: #f1f1f1;
        overflow: hidden;
        position: relative;

        left: 50%;
        transform: translate(-50%);



    }

    .container_last_world h1 {
        font-weight: 800;
        color: black;
        padding-top: 100px;
    }

    .container_last_world .container_last_world_title {
        color: black;
        margin-top: 15px;
        font-size: 16px;
    }

    .container_last_world .container_last_world_last {
        width: 410px;
        height: 250px;
        position: relative;
        transform: translate(-50%, -50%);
        left: 50%;

        top: 112px;

    }

    .container_last_world .container_last_world_last p {
        color: black;
        line-height: 30px;
        overflow: hidden;
    }

    .container_last_world button {
        height: 45px;
        width: 105px;
        background-color: black;
        color: white;
        border-radius: 5px;
        bottom: 50px;
        margin-top: 520px;
        transform: translate(-50%);
        left: 50%;
        position: absolute;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, .1);
    }


</style>