<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="header">

                <div class="col-lg-4" style="cursor:pointer">
                    <h4 style="display: none" title="暹诺环保">暹诺环保</h4>
                    <div class="imgs">
                        <img @click="backIndex" alt="not response" src="../../../assets/img/20190613194934.jpg">
                    </div>
                    <div class="lange">
                        <h4>{{$t("HeaderMessage.companyTitle")}}</h4>
                        <p>{{$t("HeaderMessage.companyDescript")}}</p>
                    </div>
                </div>
                <!--                语言切换-->


                <div class="col-lg-5" style="z-index: 9999 !important;text-align: right;position: relative">

                    <div class="nav main" style="right: 0px;position: relative;left: 115px;">
                        <ul class="autos" id="navs" style="position: absolute;right: 0px">

                            <li class="click" id="nav_one">
                                <a href="/">{{$t("HeaderNav.HeaderIndex")}}</a>
                            </li>


                            <li class="click active" id="nav_one">
                                <a @click="GoToAbout" href="javascript:;">{{$t("HeaderNav.HeaderAbout")}}</a>
                                <ul id="frist" style="background:white;width: 150px">
                                    <li class="nav_two" id="nav_two" style=";height: 40px;margin-top: 15px">
                                        <a @click="GoToprofession"
                                           href="javascript:;">{{$t("HeaderNav.HeaderProfess")}}</a>
                                    </li>
                                    <li class="nav_two" id="nav_two" style=";height: 40px">
                                        <a @click="GoToMyCustomer" href="#">{{$t("HeaderNav.HeaderMyCustomer")}}</a>
                                    </li>
                                    <li class="nav_two" id="nav_two">
                                        <a @click="GoToSuccess" href="javascript:;">{{$t("HeaderNav.HeaderSuccessSample")}}</a>
                                    </li>

                                </ul>
                            </li>
                            <li class="click active" id="nav_one">
                                <a @click="GoToProfession"
                                   href="javascript:;">{{$t("HeaderNav.HeaderprofessServe")}}</a>
                                <ul id="frist" style="background-color: white;">
                                    <li class="nav_two" id="nav_two" style="height: 40px;margin-top: 15px; ">
                                        <a @click="GoToIt" href="javascript:;">{{$t("HeaderNav.HeaderIt")}}</a>
                                    </li>
                                    <li class="nav_two" id="nav_two" style=";height: 40px;  ">
                                        <a @click="GoToSafet" href="javascript:;">{{$t("HeaderNav.HeaderSafety")}}</a>
                                    </li>
                                    <li :style="this.localLanguageName !=='en'? '' : 'background-color: white; width: 220px;'"
                                        class="nav_two"
                                        id="nav_two">
                                        <a :style="this.localLanguageName !=='en'? '' : 'margin-left:-57px'"
                                           @click="GoToPhy"
                                           href="javascript:;">{{$t("HeaderNav.HeaderPhy")}}</a>
                                    </li>

                                </ul>
                            </li>


                            <li class="click" id="nav_one" style="width: 128px">
                                <a  href="http:///disk.cinorecycle.com" target="_blank">{{$t("HeaderNav.headerWP")}}</a>
                            </li>


                        </ul>
                    </div>
                </div>


                <div class="col-lg-3" style="z-index: 9999 !important;">
                    <div class="changeLange">


                        <div @click="language.showLanguageSelectedList = !language.showLanguageSelectedList"
                             class="changeLange_world">
                            {{selectedLanguage.title}}

                        </div>

                        <div @click="language.showLanguageSelectedList = !language.showLanguageSelectedList"
                             class="changeLange_img">
                            <img :src="selectedLanguage.icon"
                                 alt="English">
                            <i @click="language.showLanguageSelectedList = !language.showLanguageSelectedList"
                               class="el-icon-arrow-down"
                               style="position: relative;left: 17px;top: 17px;font-size: 15px"
                               v-if="language.showLanguageSelectedList"></i>
                            <i class="el-icon-arrow-up" style="position: relative;left: 17px;top: 17px;font-size: 15px"
                               v-else></i>
                        </div>


                        <div class="selectLanguage" v-show="language.showLanguageSelectedList">

                            <div @click="changeLanguage(lan.name)" v-for="(lan, i) in languages">
                                <div v-if="lan.name !== localLanguageName">
                                       <span style="position: relative;left: 45px;top: 20px;color: black"
                                             v-text="lan.title"></span>
                                    <img :src=" lan.icon "
                                         alt="Chinese"
                                         style="height: 30px;width: 30px;border-radius: 50%;margin-top: 35px;margin-left: 60px">

                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>


    <!--    <div class="header">-->
    <!--        <el-row>-->
    <!--            <el-col :span="8" >-->
    <!--                <div class="grid-content bg-purple">-->
    <!--                    <p style="margin-left: 15px" @click="gotoabout">{{$t("message.my")}}</p>-->
    <!--                </div>-->
    <!--            </el-col>-->
    <!--            <el-col :span="6" >-->
    <!--                <div class="grid-content bg-purple-light">-->
    <!--                    <nav class="navbar   fixed-top " style="margin-top: -10px;background-color: red;height: 68px" >-->
    <!--                            <li class="nav-item dropdown language-dropdown d-none d-sm-flex align-items-center">-->
    <!--                                <a aria-expanded="false" class="nav-link d-flex align-items-center dropdown-toggle"-->
    <!--                                   data-toggle="dropdown" href="#" id="LanguageDropdown">-->
    <!--                                    <div class="d-inline-flex mr-3">-->
    <!--                                        <i class="flag-icon flag-icon-cn"></i>-->
    <!--                                    </div>-->
    <!--                                    <span class="profile-text font-weight-normal">中文</span>-->
    <!--                                </a>-->
    <!--                                <div aria-labelledby="LanguageDropdown"-->
    <!--                                     class="dropdown-menu dropdown-menu-left navbar-dropdown py-2" style="margin-top: -15px;margin-left: -15px">-->
    <!--                                    <a class="dropdown-item">-->
    <!--                                        <i class="flag-icon flag-icon-us"></i> 英文 </a>-->
    <!--                                </div>-->
    <!--                            </li>-->
    <!--                    </nav>-->
    <!--                </div>-->
    <!--            </el-col>-->
    <!--            <el-col :span="10">-->
    <!--                <div class="grid-content bg-purple">-->
    <!--                    <p style="margin-left: 15px">{{$t("message.your")}}</p>-->
    <!--                </div>-->

    <!--            </el-col>-->

    <!--        </el-row>-->
    <!--    </div>-->
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "Head",
        data() {
            return {
                languages: [
                    {
                        name: 'en',
                        icon: 'https://edu-xiaomi.oss-cn-beijing.aliyuncs.com/qxk/U.svg',
                        title: 'English'
                    },
                    {
                        name: 'zh',
                        icon: 'https://edu-xiaomi.oss-cn-beijing.aliyuncs.com/qxk/C.svg',
                        title: '中文'
                    },


                ],
                language: {
                    //selectedLanguageName: '',
                    showLanguageSelectedList: false,

                },

                selectLangeName:'en'
            }

        },
        created() {
            // 判斷語言、
            //  this.language.selectedLanguageName = this.$store.getters.localLanguage
            //  this.language.selectedLanguageName = this.$store.getters.localLanguage

        },
        mounted() {
            this.selectLangeName =  this.$i18n.locale = localStorage.getItem('locale')
            console.log(this.localLanguageName )

        },
        computed: {
            ...mapGetters([
                'localLanguageName'
            ]),
            selectedLanguage() {
                return this.languages.find(lang => lang.name === this.localLanguageName)
            }
        },
        methods: {
            GoToAbout() {

                this.$router.push('/about')
            },
            GoToprofession() {
                this.$router.push('/profession')
            },
            GoToSuccess() {
                this.$router.push('/successsample')
            },
            GoToMyCustomer() {
                this.$router.push('/mycustomer')
            },
            GoToProfession() {
                this.$router.push('/professionserve')
            },
            GoToIt() {
                this.$router.push('/itciorecycle')
            },
            GoToSafet() {
                this.$router.push('/safetyanddestroy')
            },
            GoToPhy() {
                this.$router.push('/physicalserve')
            },
            backIndex() {
                this.$router.push('/')
            },
            cionwangpan(lanName) {


                if (lanName === 'zh') {
                    this.$message.success('建设中')
                } else if (lanName === 'en' || '') {
                    this.$message.success('under construction')
                }



            },

            changeLanguage(lanName) {
                // 1. 獲取被選擇的語言
                //this.language.selectedLanguageName = lanName
                this.$store.dispatch('SetLocalLanguage', lanName)
                //this.$store.dispatch('ClearLanguage')
                // 2. 改變語言

                localStorage.setItem('locale', this.localLanguageName)
                this.$i18n.locale = localStorage.getItem('locale')

                if (lanName === 'zh') {
                    this.$message.success('切换为中文')
                } else if (lanName === 'en') {
                    this.$message.success('Switch to English!')
                }
                this.selectLangeName = lanName
                // this.$message({
                //     message: '切换为' + this.selectedLanguage.title + '!',
                //     type: 'success'
                // })


                this.language.showLanguageSelectedList = false
            }

        }
    }


</script>

<style>

    .rotates {
        transform: rotate(180deg);
        transition: linear .2s;
    }

    .rotatess {
        transform: rotate(0deg);
        transition: linear .2s;
    }

    .header {
        height: 152px;
        width: 1890px;
        top: 0px;
        z-index: 99;
        margin-left: -13px;
        border-bottom: 2px solid skyblue;
    }

    * {
        margin: 0;
        padding: 0;
    }


    .col-lg-12 .header .col-lg-3 {
        float: left;
        width: 450px !important;
        height: 100%;

    }

    .col-lg-12 .header .col-lg-4 {
        float: left;
        height: 100%;
        width: 550px !important;


    }

    .col-lg-12 .header .col-lg-5 {
        float: left;

        width: 600px !important;


    }

    .col-lg-4 .imgs {
        height: 100%;
        width: 230px;
        float: left;
    }

    .lange {

        width: 240px;

        margin-left: 225px;


    }

    .col-lg-12 .header .col-lg-4 .lange h4 {

        font-weight: 800;
        color: black;
        margin-left: 0px;
        margin-top: 45px;


    }

    .lange p {
        margin-top: 5px;
        color: black;
    }

    .col-lg-12 .header .col-lg-4 img {
        height: 140px;
        width: 150px;
        margin-left: 75px;
        float: left;
        margin-top: 0px;

    }

    .changeLange {
        height: 57px;
        width: 200px;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 15px;
        cursor: pointer;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);


    }

    .changeLange .changeLange_img {
        height: 55px;
        width: 80px;
        float: left;
        margin-left: 25px;


    }

    .changeLange .changeLange_img img {
        height: 25px;
        width: 25px;
        position: relative;
        top: 25%;

    }

    .changeLange .changeLange_world {
        text-align: center;
        line-height: 55px;
        width: 85px;

       position: relative;
        left: 15px;
        float: left;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

    }

    .changeLange .changeLange_world i {
        float: right;
        position: relative;
        line-height: 55px;



    }

    .selectLanguage {
        height: 150px;
        width: 200px;
        border-radius: 15px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }


    .main {
        width: 550px;
        margin: 70px auto;
        height: 250px;
        top: 0px;
        z-index: 99;


    }


    a {
        text-decoration: none !important;
        outline: none;
        color: black;
        font-size: 16px;
        font-weight: 500;

    }

    ul,
    ol {
        list-style: none;
    }


    li {
        float: left;
        width: 118px;
        margin: 0px 1px;
        height: 45px;
        text-align: center;
        line-height: 20px;
        position: relative;
        cursor: pointer;

    }


    #frist {
        display: none;


    }

    #frist li {
        float: none;
        position: relative;

    }


    #nav_one:hover #frist {
        display: block;
        transition: all 0.1s;
    }


    .active {

        background-size: 100% 100%;
    }


    .autos {
        display: inline-block;
        height: 45px;
        width: 530px;
        position: absolute;
        right: 100px;


    }

    #second li {
        width: 170px;


    }

    #nav_one #nav_two a {
        color: black;
        font-weight: 500;
        font-size: 15px;
        display: inline-block;
        width: 90%;


        text-overflow: ellipsis;
        white-space: nowrap;


    }

    #nav_one #nav_two a:hover {
        color: orange;
    }

    .nav_two a {
        color: black;
        font-weight: 500;
        font-size: 12px;

    }


    #list img {
        border-radius: 21px;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.5s all ease;
    }


    #frist #nav_two {
        display: inline-block;

    }

    /*头部/
     *
     */
</style>
