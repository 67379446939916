<template>
    <div class="row">
        <div class="col-lg-12" style="height: 250px;width: 100%;margin-top: 105px">
            <div class="container_bottom">
                <div class="bottom_top">
                    <h4>{{$t('BottomMessage.BottomCallAbout')}} </h4>
                    <div class="bottom_first"></div>
                    <div class="bottom_second"></div>
                    <div class="bottom_world">
                        <p>{{$t('BottomMessage.BottomPhone')}}：4008-959-536</p>
                        <p>{{$t('BottomMessage.BottomEmail')}}：info@cinorecycle.com</p>
                        <p>{{$t('BottomMessage.BottomWchart')}}：cinorecycle</p>
                        <p>{{$t('BottomMessage.BottomAddress')}}</p>
                    </div>
                </div>
                <div class="bottom_bottom">
                    <img src="../../../assets/images/erweima.png" alt="">
                    <div class="bottom_world_bottom">
                        <p>
                            {{$t('BottomMessage.BottomEweima')}}</p>
                        <p>
                        <span style="color: #b3b3b3">©{{$t('BottomMessage.BottomTime') + newTime}}</span>
                            {{$t('BottomMessage.BottomCompany')}}
                            <a style="color: white;font-size: 12px" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{$t('BottomMessage.BottomCompany00')}}</a>
                        </p>


                        <p> {{$t('BottomMessage.BottomCompany01')}}</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Bottom",
        data() {
            return {
                url: '',
                newTime:''
            }
        },
        created() {
            const date = new Date()

            this.newTime = date.getFullYear() + ''


        }
    }
</script>

<style scoped>

    .col-lg-12 .container_bottom {
        height: 680px;
        width: 100%;
        background: url("../../../assets/image/Bottom/bottom.jpg") no-repeat;
        background-size: 100% 100%;
    }

    .container_bottom .bottom_top {
        height: 250px;
        width: 70%;
        position: relative;
        left: 55%;
        transform: translate(-50%);
        top: 15%;
        cursor: pointer;
    }
    .container_bottom .bottom_top h4{
        color: white;
        font-size: 35px;
        font-weight: 800;
        margin-left: 125px;
        margin-top: 35px;
    }
    .bottom_top .bottom_first{
        height: 1px;
        width: 80%;
        margin-left: 125px;
        background-color: white;
    }
    .bottom_top .bottom_second{
        height: 3px;
        width: 18%;
        margin-left: 125px;
        background-color: white;
    }
    .bottom_top .bottom_world{
        height: 125px;
        width: 70%;
        overflow: hidden;
        margin-left: 125px;
        margin-top: 15px;

    }
    .bottom_world p{
        line-height: 15px;
        color: white;
    }

    .bottom_bottom {
        height: 200px;
        width: 50%;

        position: relative;
        left: 50%;
        transform: translate(-50%);
        top: 15%;
        margin-top: 70px;
        text-align: center;
    }
    .bottom_bottom img{
        height: 120px;
        width: 120px;

    }
    .bottom_bottom .bottom_world_bottom p{
        color: white;
        font-size: 12px;
        margin-top: 25px;
        line-height: 5px;
    }


</style>
